<template>
  <div class="address-list">
    <!-- 头部 -->
    <van-nav-bar
      title="收货地址"
        right-text="新增地址"
      left-arrow
      @click-left="onClickLeft"
       @click-right="onAdd"
      fixed
      :z-index="100"
      class="dark_bg"
    />
    <!-- 内容 -->
    <van-loading size="24px" vertical v-if="showLoading">加载中...</van-loading>
    <!-- <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @edit="onEdit"
      @add="onAdd"
      @select="select"
      class="dark_bg"
      @click="goback"
    ></van-address-list> -->

      <div>
            <van-radio-group  v-model="isDefault" >
                  <div >
                        <div  v-for="v in list" :key="v.id"  class="list isDefault" @click='select(v)' >
                          <van-radio class="isDefault-list"  icon-size=".24rem"  :name="v.id" @click.stop="changeDefault(v)">
                          </van-radio>

                          <div class="list-top">
                            <div class="list-top-left">
                              <div class="list-top-city"><span v-show="v.isDefault" class="default-text">默认</span> {{v.province +  v.city + v.county}}</div>
                              <div class="list-top-address"> {{v.address}} </div>
                              <div class="list-top-user"><span>{{v.name}}</span> {{v.phoneShow}}</div>
                            </div>
                            <div class="list-top-edit"><van-icon @click="onEdit(v)" name="edit" color="#AEAEAE" size=".32rem" /></div>
                          </div>
                          <div class="list-bottom">
                            <div class="list-bottom-default">默认地址</div>
                            <div class="list-bottom-delete" @click.stop="onDelete(v)">删除</div>
                          </div>
                        </div>
                  </div>
            </van-radio-group>
            
      </div>
    
  </div>
</template>

<script>
import { NavBar, AddressList, Loading, Toast } from "vant";
let userId = localStorage.getItem("customerId");
export default {
  data() {
    return {
      chosenAddressId: "",
      showLoading: true, // 是否显示加载提示
      list: [], // 地址列表
      backPath: null, // 返回地址
      isDefault:''
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [AddressList.name]: AddressList,
    [Loading.name]: Loading,
  },
  created() {
    this.getAddressList();
  },
  methods: {
    //  获取收货地址
    getAddressList() {
      // this.$api.my
      //   .addressListAddress({
      //     userId: loginToken,
      //   })
      this.$api.my
        .addressListAddress({
          userId,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.showLoading = false;
            this.list = res.data.map((item) => {
              // console.log(item);
              if(item.defaultAddress === 1){
                item.isDefault = true
              }
              item.isDefault = item.isDefault == "1";
              item.tel = item.phone;
              item.name = item.consignee;
              // item.address =
              //   item.province + item.city + item.county + " " + item.address;

              // console.log(item.phone);

              let phoneArr= item.phone.split('')
               phoneArr.splice(3,4,'****')
              item.phoneShow = phoneArr.join('')

              return item;
            });
            this.chosenAddressId = this.list[0] ? this.list[0].id : "";
           

            console.log(this.list);
          } else {
            this.showLoading = false;
          }
        });
    },
    changeDefault(item){
      console.log(item);

       const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "修改中",
      });

      if (item.id) {
        item.isDefault = true;
        item.addressId = item.id;
        item.defaultAddress = 1;
        this.$api.my
          .changeAddressListAddress(
            item
          )
          .then((res) => {
            if (res.errno === 200) {
              this.getAddressList();
              toast.clear();
            } else {
              this.$toast.fail(res.message);
            }
          });
      }
    },

      // 删除
    onDelete(item) {
      // console.log(item);
      // return
      this.isDeleting = true;
      this.$api.my
        .delAddressListAddress({
          addressId: item.id,
          userId,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.isDeleting = false;
            this.getAddressList();

            this.$toast({
              message: "删除成功",
              onClose: () => {
                // this.$router.back();
              },
            });
          } else {
            this.isDeleting = false;
            this.$toast.fail(res.message);
          }
        });
    },
    onClickLeft() {
      this.$router.back(0);
    },
    goback() {
      this.$router.go(-1);
    },
    onAdd() {
      this.$router.push("/editaddress");
    },
    onEdit(item) {
      this.$router.push({ name: "editaddress", params: { address: item } });
    },
    // 修改地址
    select(item) {
      // console.log(item);
      // 从我的页面进入的话就不执行下面的代码
      if (!this.$route.query.orderId) {
        return false;
      }
      
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "修改中",
      });

      if (item.id) {
        item.isDefault = true;
        item.addressId = item.id;
        item.defaultAddress = 1;
        // item.address = item.address.split(" ")[1];
        this.$api.my
          .changeAddressListAddress(
            item
            // accessToken: localStorage.token,
            // userId: localStorage.getItem("customerId"),
            // consignee: item.name,
            // phone: item.tel,
            // address: item.address,
            // // defaultAddress: item.address,
            // addressId: item.id,
            // isDefault: "1",
            // province: item.province,
            // city: item.city,
            // county: item.county,
            // areaCode: item.areaCode,
          )
          .then((res) => {
            if (res.errno === 200) {
              // this.isSaving = false;
              toast.clear();

              this.$router.back(0);
            } else {
              this.$toast.fail(res.message);
            }
          });
      }

      // this.$server
      //   .addressChangeAddress({
      //     accessToken: localStorage.token,
      //     orderId: this.$route.query.orderId,
      //     addressId: item.id,
      //   })
      //   .then((res) => {
      //     toast.clear();
      //     this.$router.back(0);
      //   });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      vm.$data.backPath = from.name;
    });
  },
};
</script>

<style lang='less' scoped>
// @import "../../../less/element.less";
@red: #c83f3f;
.address-list {
  width: 100%;
  // height: 100vh;
  box-sizing: border-box;
  // background-color: #f0f0f0;
  // padding: 3.2rem 0 0rem;
  padding: .92rem 0 0rem;
  .van-nav-bar {
    color: #373737;
    letter-spacing: 1px;
    margin: 0 0 1rem;
    .van-icon {
      color: #373737;
    }
    .van-nav-bar__text {
      color: @red;
    }
  }
  .van-address-list {
    // padding: 0rem 0.5rem;

    // .van-cell {
    //   margin: 0 0 0.4rem;
    // }
    .van-address-list__add {
      // background-color: @red;
      // background: linear-gradient(0deg, #EE3A3A, #F46161);
      background: @radio_checked_color;
      border-radius: .1rem;
      font-size: .36rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .36rem;
    }
  }
}
.van-address-item {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
}
.list{
  display: flex;
  flex-direction: column;
  margin: .3rem;
  padding: .26rem .3rem .2rem;
  box-sizing: border-box;
  width: 6.9rem;
  // height: 2.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0px .2rem 0px rgba(20, 20, 20, 0.1);
  border-radius: .2rem;
  font-size: .23rem;
  font-family: PingFang SC;
  font-weight: 400;
  .list-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .3rem;
    border-bottom: 1px solid #f4f4f4;

    .list-top-left{
      display: flex;
      flex-direction: column;

      .list-top-city{
        font-size: .26rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #7E7E7E;
        display: flex;
        align-items: center;
        .default-text{
          margin-right: .1rem;
          display: inline-block;
          width: .52rem;
          height: .26rem;
          // background: linear-gradient(-30deg, #EE3A3A, #F46161);
          background: @default_corner_mark_color;
          border-radius: .05rem;
          font-size: .2rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          // line-height: .32rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .list-top-address{
        margin-top: .12rem;
        font-size: .3rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .list-top-user{
        margin-top: .12rem;

        font-size: .26rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #7E7E7E;
      }

    }
  }
  .list-bottom{
    display: flex;
    justify-content: space-between;
    margin-top: .2rem;
      font-size: .26rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #7E7E7E;
      // line-height: .5rem;
      .list-bottom-default{
        padding-left: .2rem;
      }

  }
}
.isDefault{
  position: relative;
  .isDefault-list{
    position: absolute;
    bottom: .26rem;
    left: .2rem;
  }
}
/deep/ .van-nav-bar__text{
    font-size: .32rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: @nav_bar_text_color;
}
.van-radio__icon--checked .van-icon {
  
}
</style>
